
import {Component, Ref, Vue} from "vue-property-decorator";
import XmlController from "@/plugins/uyap-plugin/takip_ac/XmlController";
import {VForm} from "@/types";
import * as xml2js from "xml2js";
import {JobType} from "@/enum/JobType";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {ProgramPaths} from "@/enum/ProgramPaths";

@Component({
  components: {DeleteDialog}
})
export default class AktarView extends Vue {
  @Ref('form') readonly xmlForm!: VForm;
  valid = false;
  onFly = false;
  xml = null;
  items = [];
  loadItems=false;
  headers = [
    {text: 'İşlem No', value: 'id', class: 'header-ad-style'},
    {text: 'Olşuturulma Tarihi', value: 'created_at', class: 'header-ad-style'},
    {text: 'Dosya Sayısı', value: 'file_count', class: 'header-ad-style'},
    {text: 'İşlemler', value: 'actions',align:'end', class: 'header-actions-style'},
  ]

  mounted() {
    this.load();
  }

  load() {
    this.loadItems=true;
    this.$http.get('/api/v1/job?type_id=2').then(res => {
      this.items = res;
    }).finally(()=>(this.loadItems=false));

  }

  fileRule(file: any) {
    if (file) {
      let uzanti = file.name.split('.').pop();
      if (["xml", "XML"].indexOf(uzanti) >= 0)
        return true;
      else
        return "Sadece xlm dosya yüklenebilir.";
    } else
      return 'Lütfen bu alanı doldurun.';
  }

  bolArray(dizi: Array<any>, boyut: number): Array<Array<any>> {
    let sonuc: Array<Array<any>> = [];
    let altDizi: Array<any> = [];
    for (let i = 0; i < dizi.length; i++) {
      if (i % boyut === 0) {
        altDizi = [];
        sonuc.push(altDizi);
      }
      altDizi.push(dizi[i]);
    }
    return sonuc;
  }

  async xmlToJson(xmlData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const parser = new xml2js.Parser({
        explicitArray: false,
        mergeAttrs: true,
        // @ts-ignore
        emptyTag: null
      });
      parser.parseString(xmlData, (err: any, result: any) => {
        if (err) {
          console.error('XML çevrim hatası:', err);
          reject(err)
        } else {
          resolve(result);
        }
      });
    })
  }

  detay(item:any){
    this.$router.push(ProgramPaths.ofispro + "/aktar-detay/" + item.id);
  }

  async xmlAktar() {
    if (this.xmlForm.validate()) {
      try {
        this.onFly = true;
        // @ts-ignore
        let xml = await XmlController.readXmlWithEncoded(this.xml);
        let data = (await this.xmlToJson(xml)).exchangeData
        if (!Array.isArray(data.dosyalar.dosya)) {
          data.dosyalar.dosya = [data.dosyalar.dosya]
        }
        let dosyalar: Array<any> = data.dosyalar.dosya;
        for (let dosya of dosyalar) {
          if (dosya.hacizler?.haciz && !Array.isArray(dosya.hacizler.haciz))
            dosya.hacizler.haciz = [dosya.hacizler.haciz];
          if (dosya.masrafCesitleri?.masrafCesidi && !Array.isArray(dosya.masrafCesitleri.masrafCesidi))
            dosya.masrafCesitleri.masrafCesidi = [dosya.masrafCesitleri.masrafCesidi];
          if (dosya.masraflar?.masraf && !Array.isArray(dosya.masraflar.masraf))
            dosya.masraflar.masraf = [dosya.masraflar.masraf];
          if (dosya.satislar?.satis && !Array.isArray(dosya.satislar.satis))
            dosya.satislar.satis = [dosya.satislar.satis];
          if (dosya.tahsilatlar?.tahsilat && !Array.isArray(dosya.tahsilatlar.tahsilat))
            dosya.tahsilatlar.tahsilat = [dosya.tahsilatlar.tahsilat];
          if (dosya.tebligatlar?.tebligat && !Array.isArray(dosya.tebligatlar.tebligat))
            dosya.tebligatlar.tebligat = [dosya.tebligatlar.tebligat];
          if (dosya.notlar?.not && !Array.isArray(dosya.notlar.not))
            dosya.notlar.not = [dosya.notlar.not];
        }
        let bolunmusDosyalar: Array<Array<any>> = this.bolArray(dosyalar, 100);
        for (const bolunmusDosya of bolunmusDosyalar) {
          await this.$http.post('/api/v1/job', {
            type_id: JobType.IcwAktar,
            status: "WAITING",
            args: bolunmusDosya
          });
        }
        this.load();
        this.$toast.success("Xml'den İcrapro'ya aktarma işlemi sıraya alındı.");
      } catch (e) {
        console.error(e);
        this.$toast.success("Xml'den İcrapro'ya aktarma işleminde hata oluştu. " + e.message);
      } finally {
        this.onFly = false;
      }
    }
  }
}
